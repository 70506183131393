import { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Table as MuiTable,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableSortLabel as MuiTableSortLabel,
  TablePagination as MuiTablePagination,
  Typography as MuiTypography,
  Paper as MuiPaper,
  Checkbox as MuiCheckbox,
} from "@mui/material";
import { Search as MuiSearchIcon } from "@mui/icons-material";
/** Custom Components */
import { AuditInfo } from "app/shared/ui/AuditInfo/AuditInfo";
import CustomTable from "app/shared/ui/Table/Table";
import CustomDialog from "app/shared/ui/Dialog/Dialog";
import { ConfirmationDialog } from "app/shared/ui/ConfirmationDialog";
import { ShowSnackBar } from "app/shared/ui/ActionTrigger";
import { traineeTypeDropDown, EthnicityValues } from "app/shared/constants";
import AddRemoveActionIcons from "app/shared/ui/Layout/AddRemoveActionIcons/AddRemoveActionIcons";
/** Services */
import { useApplicantsFilterContext } from "app/services/applicantContext";
import { useSelectedSubmission } from "app/services/selectedSubmissionService";
import {
  getAllApplicantsList,
  getApplicantById,
  removeAddApplicantById,
} from "app/services/applicantService";
import {
  useApplicantsSelectedContext,
  SelectAllStates,
} from "app/services/applicantsSelectedContext";
/** Context and Component to show the error on UI */
import { Theme, useAlerts, useUsers } from "common";
/** Styles */
import {
  tableContainerRoot,
  tableHeadCheckbox,
  whiteTableHeaderLabel,
} from "app/shared/ui/sharedStyles";

const EnhancedTableHead = (props) => {
  const { t } = useTranslation();
  const {
    currentUser: { permissions },
  } = useUsers();
  const { onRequestSort, order, orderBy } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  let { ALL_SELECTED, SOME_SELECTED } = SelectAllStates;
  const { applicantsSelected, handleSelectAllChange } =
    useApplicantsSelectedContext();
  const applicantTableLabel = "applicants.mainView.list.tableColumns";
  return (
    <MuiTableHead sx={whiteTableHeaderLabel}>
      <MuiTableRow
        sx={{
          verticalAlign: "top",
        }}
      >
        <MuiTableCell align="left" width="50px">
          <MuiCheckbox
            sx={tableHeadCheckbox}
            size="small"
            checked={applicantsSelected.selectAllState === ALL_SELECTED}
            indeterminate={applicantsSelected.selectAllState === SOME_SELECTED}
            onChange={handleSelectAllChange}
            disabled={!permissions.REMOVE_APPLICANT_FROM_ALL_SUBMISSIONS}
          />
        </MuiTableCell>
        <MuiTableCell
          align="center"
          key="searchIcon"
          width="40px"
        ></MuiTableCell>
        <MuiTableCell
          align="left"
          key="applicantName"
          sortDirection={orderBy === "applicantName" ? order : "asc"}
          width="20%"
        >
          <MuiTableSortLabel
            direction={orderBy === "applicantName" ? order : "asc"}
            onClick={createSortHandler("applicantName")}
            active={orderBy === "applicantName"}
          >
            {t(`${applicantTableLabel}.applicantName`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell align="left" key="participatingDepartment" width="20%">
          {" "}
          {t(`${applicantTableLabel}.participatingDepartment`)}
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="applicantType"
          sortDirection={orderBy === "applicantType" ? order : "asc"}
        >
          <MuiTableSortLabel
            direction={orderBy === "applicantType" ? order : "asc"}
            onClick={createSortHandler("applicantType")}
            active={orderBy === "applicantType"}
          >
            {t(`${applicantTableLabel}.applicantType`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="applicationYear"
          sortDirection={orderBy === "applicationYear" ? order : "asc"}
        >
          <MuiTableSortLabel
            direction={orderBy === "applicationYear" ? order : "asc"}
            onClick={createSortHandler("applicationYear")}
            active={orderBy === "applicationYear"}
          >
            {t(`${applicantTableLabel}.applicationYear`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="acceptedYear"
          sortDirection={orderBy === "acceptedYear" ? order : "asc"}
        >
          <MuiTableSortLabel
            direction={orderBy === "acceptedYear" ? order : "asc"}
            onClick={createSortHandler("acceptedYear")}
            active={orderBy === "acceptedYear"}
          >
            {t(`${applicantTableLabel}.acceptedYear`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="isNewEntrant"
          sortDirection={orderBy === "isNewEntrant" ? order : "asc"}
        >
          <MuiTableSortLabel
            direction={orderBy === "isNewEntrant" ? order : "asc"}
            onClick={createSortHandler("isNewEntrant")}
            active={orderBy === "isNewEntrant"}
          >
            {t(`${applicantTableLabel}.newEntrant`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell
          align="left"
          key="isGrantEligible"
          sortDirection={orderBy === "isGrantEligible" ? order : "asc"}
        >
          <MuiTableSortLabel
            direction={orderBy === "isGrantEligible" ? order : "asc"}
            onClick={createSortHandler("isGrantEligible")}
            active={orderBy === "isGrantEligible"}
          >
            {t(`${applicantTableLabel}.grantEligible`)}
          </MuiTableSortLabel>
        </MuiTableCell>
        <MuiTableCell align="left" key="actions" sx={{ width: "100px" }}>
          {t("trainee.mainView.list.tableColumns.actions")}
        </MuiTableCell>
      </MuiTableRow>
    </MuiTableHead>
  );
};

export const ApplicantsDetails = (props, ref) => {
  const { applicantsFilterPreferences, setApplicantsFilterPreferences } =
    useApplicantsFilterContext();

  const [open, setOpen] = useState(false);
  const [popupApplicant, setPopupApplicant] = useState({
    name: null,
    id: null,
  });
  const { t } = useTranslation();
  const applicantMainViewMessage = "applicants.mainView";
  // const { ignoreSelection } = useApplicantsSelectedContext();
  const order = useMemo(
    () => applicantsFilterPreferences.applicantValues.sort.order || "asc",
    [applicantsFilterPreferences]
  );
  const orderBy = useMemo(
    () =>
      applicantsFilterPreferences.applicantValues.sort.orderBy ||
      "applicantName",
    [applicantsFilterPreferences]
  );
  const page = useMemo(
    () => applicantsFilterPreferences.applicantValues.pagination.page || 0,
    [applicantsFilterPreferences]
  );
  const pageSize = useMemo(
    () => applicantsFilterPreferences.applicantValues.pagination.pageSize || 10,
    [applicantsFilterPreferences]
  );
  const { setMetaData } = useSelectedSubmission();
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  const [snackbar, setSnackbar] = useState(false);
  const [confirmation, setConfirmation] = useState({});
  const [actionType, setActionType] = useState("");
  const [applicantsList, setApplicantsList] = useState({});
  const [response, setResponse] = useState([]);
  const selectedSubmission = getSelectedSubmission();

  const { navigate, setLoading, totalCount, setSummary } = props;
  const [dialogLoading, setDialogLoading] = useState(false);

  const { setAlert, clearAlert } = useAlerts();

  /**
   * Open Modal Box or Perform any other linking functionality.
   *
   * @param applicantName
   *   Applicant Name.
   * @param applicantId
   *   Applicant ID.
   */
  const handleDialogOpenHandler = (applicantName, applicantId) => {
    setOpen(true);
    setPopupApplicant({ name: applicantName, id: applicantId });
  };

  /**
   * Dialog Close Button Handler.
   */
  const handleDialogCloseHandler = () => {
    setOpen(false);
    setPopupApplicant({
      name: null,
      id: null,
    });
  };

  /**
   * Filtering Popup Data.
   *
   * @param data
   *   Data Returned from the popup api call
   */
  const filterPopupData = (data) => {
    let response = {};
    // Check if data returned from API Call.
    const applicantsMainViewPopup = "applicants.mainView.popup";
    if (typeof data !== "undefined" && Object.keys(data).length !== 0) {
      const degreesArray = [];
      if (data.degrees) {
        for (let response of data.degrees) {
          degreesArray.push(response.shortName);
        }
      }
      let applicantType = "";
      if (data.applicantType) {
        applicantType = t(`${applicantsMainViewPopup}.${data.applicantType}`);
      }
      response = {
        1: {
          "#": t(`${applicantsMainViewPopup}.applicantType`),
          name: applicantType,
        },
        2: {
          "#": t(`${applicantsMainViewPopup}.applicationYear`),
          name: data.applicationYear ?? "N/A",
        },
        3: {
          "#": t(`${applicantsMainViewPopup}.acceptedYear`),
          name: data.acceptedYear ?? "N/A",
        },
        4: {
          "#": t(`${applicantsMainViewPopup}.isNewEntrant`),
          name: data.isNewEntrant ? "Yes" : "No",
        },
        5: {
          "#": t(`${applicantsMainViewPopup}.isGrantEligible`),
          name: data.isGrantEligible ? "Yes" : "No",
        },
        6: {
          "#": t(`${applicantsMainViewPopup}.isUnderrepresented`),
          name: data.isUnderrepresented ? "Yes" : "No",
        },
        7: {
          "#": t(`${applicantsMainViewPopup}.priorInstitution`),
          name: data.priorInstitution ?? "",
        },
        8: {
          "#": t(`${applicantsMainViewPopup}.citizenship`),
          name: data.citizenship ? data.citizenship.name : "",
        },
        9: {
          "#": t(`${applicantsMainViewPopup}.ethnicity`),
          name: t(EthnicityValues[data.ethnicity]) ?? "",
        },
        10: {
          "#": t(`${applicantsMainViewPopup}.degrees`),
          name: degreesArray.join(", "),
        },
        11: {
          "#": t(`${applicantsMainViewPopup}.totalPublications`),
          name: data.totalPublications ?? "",
        },
        12: {
          "#": t(`${applicantsMainViewPopup}.totalPublicationsFirstAuthor`),
          name: data.totalPublicationsFirstAuthor ?? "",
        },
      };
    }
    if (data.applicantType === "PRE_DOC") {
      const departmentsArray = [];
      if (data.departments) {
        for (let response of data.departments) {
          departmentsArray.push(response.name);
        }
      }
      response[10] = {
        "#": t(`${applicantsMainViewPopup}.departments`),
        name: departmentsArray.join(", "),
      };
      response[11] = {
        "#": t(`${applicantsMainViewPopup}.ftResearchExperience`),
        name: data.ftResearchExperience ?? "",
      };
      response[12] = {
        "#": t(`${applicantsMainViewPopup}.gpa`),
        name: data.gpa ?? "",
      };
    }
    return response;
  };

  // Build Modal Box data
  let dialogBox = null;
  const modalBody = filterPopupData(response);

  useEffect(() => {
    if (popupApplicant.id) {
      getApplicantById(
        selectedSubmission,
        popupApplicant.id,
        setResponse,
        setAlert,
        clearAlert,
        setDialogLoading
      );
    }
    // eslint-disable-next-line
  }, [selectedSubmission, popupApplicant.id]);

  if (open) {
    const modalTitle = popupApplicant.name;
    dialogBox = (
      <CustomDialog
        closeButtonText="CLOSE"
        title={modalTitle}
        open={open}
        handleClose={handleDialogCloseHandler}
        extraClass="applicantDialogBox"
      >
        <CustomTable
          label={modalTitle}
          body={modalBody}
          tableClass={{
            "& tbody tr td:first-of-type": {
              width: "40%",
              backgroundColor: "#F0F4F5",
              color: "#666666",
            },
          }}
          loading={dialogLoading}
        />
      </CustomDialog>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc" ? "desc" : "asc";
    setApplicantsFilterPreferences({
      ...applicantsFilterPreferences,
      applicantValues: {
        ...applicantsFilterPreferences.applicantValues,
        sort: {
          order: isAsc,
          orderBy: property,
        },
      },
    });
  };
  const handleChangePage = (event, newPage) => {
    setApplicantsFilterPreferences({
      ...applicantsFilterPreferences,
      applicantValues: {
        ...applicantsFilterPreferences.applicantValues,
        pagination: {
          ...applicantsFilterPreferences.applicantValues.pagination,
          page: +newPage,
        },
      },
    });
  };
  const handleChangeRowsPerPage = (event) => {
    let pageValue = page;
    const emptyRows = Math.min(
      parseInt(event.target.value, 10),
      totalCount - page * parseInt(event.target.value, 10)
    );
    if (emptyRows < 0) {
      pageValue = 0;
    }

    setApplicantsFilterPreferences({
      ...applicantsFilterPreferences,
      applicantValues: {
        ...applicantsFilterPreferences.applicantValues,
        pagination: {
          ...applicantsFilterPreferences.applicantValues.pagination,
          pageSize: parseInt(event.target.value, 10),
          page: pageValue,
        },
      },
    });
  };

  const stringifiedApplicantsFilterPreferences = JSON.stringify(
    applicantsFilterPreferences
  );

  useEffect(() => {
    getAllApplicantsList(
      setApplicantsList,
      setSummary,
      selectedSubmission,
      setLoading,
      applicantsFilterPreferences,
      navigate,
      setAlert,
      clearAlert,
      setMetaData,
      getSelectedSubmissionName,
      t
    );

    // eslint-disable-next-line
  }, [
    page,
    pageSize,
    order,
    orderBy,
    stringifiedApplicantsFilterPreferences,
    selectedSubmission,
    navigate,
  ]);

  /**
   * Enable/disable applicant on click of add/remove
   * and display snackbar
   * @param {*} applicantId
   * @param {*} row
   * @param {*} type
   */
  const toggleApplicant = (applicantId, row, type) => {
    setSnackbar(false);
    setActionType(type);

    removeAddApplicantById(
      getSelectedSubmission(),
      applicantId,
      row,
      setSnackbar,
      setAlert,
      clearAlert,
      setLoading,
      applicantsFilterPreferences,
      setApplicantsFilterPreferences
    );
  };

  /**
   *
   * @param {*} action
   *
   */

  const isSelected = (applicantsSelected, row) => {
    if (!row.isActive) return false;

    const rowId = row._links.self.href;

    if (applicantsSelected.selectAllState === SelectAllStates.ALL_SELECTED) {
      return true;
    } else if (
      applicantsSelected.selectAllState === SelectAllStates.NONE_SELECTED
    ) {
      return !!applicantsSelected.individualSelections[rowId];
    } else {
      return applicantsSelected.individualSelections[rowId] !== false;
    }
  };
  const handleRemove = (action) => {
    toggleApplicant(
      confirmation.applicantId,
      confirmation.row,
      confirmation.type
    );

    setConfirmation({
      ...confirmation,
      confirm: false,
    });
  };

  /**
   * remove icon click handler
   * @param {*} applicantId
   * @param {*} row
   * @param {*} type
   */
  const handleConfirmation = (applicantId, row, type) => {
    setConfirmation({
      applicantId: applicantId,
      row: row,
      type: type,
      confirm: true,
    });
  };

  /**
   * Display Add/Remove Applicant Snackbar Message
   */
  const showConfirmationSnackBar = () =>
    snackbar ? (
      <ShowSnackBar
        message={
          actionType === "delete"
            ? t(`${applicantMainViewMessage}.removeApplicantMessage`)
            : t(`${applicantMainViewMessage}.addApplicantMessage`)
        }
      />
    ) : null;

  /**
   * Display Remove Confirmation Dialog
   */
  const showDialogRemoveConfirmation = () =>
    confirmation.confirm ? (
      <ConfirmationDialog
        handleCancel={() =>
          setConfirmation({
            ...confirmation,
            confirm: false,
          })
        }
        handleOk={() => handleRemove()}
        open={true}
        title={t(`${applicantMainViewMessage}.delete.confirmationBox.title`)}
        message={t(`${applicantMainViewMessage}.delete.confirmationBox.body`, {
          name: confirmation.row.applicantName,
          count: "",
          plural: "",
        })}
        okLabel={t(
          `${applicantMainViewMessage}.delete.confirmationBox.okLabel`
        )}
        cancelLabel={t(
          `${applicantMainViewMessage}.delete.confirmationBox.cancelLabel`
        )}
      />
    ) : null;

  const { applicantsSelected, handleSelectionChange } =
    useApplicantsSelectedContext();

  const getDisabledRowStyles = (row) =>
    !row.isActive ? { color: Theme.palette.grey[300] } : {};
  return (
    <>
      {showConfirmationSnackBar()}
      {showDialogRemoveConfirmation()}
      <MuiTableContainer sx={tableContainerRoot} component={MuiPaper}>
        <MuiTable
          aria-label="customized table"
          stickyHeader
          sx={{
            "& .MuiTableCell-root": {
              wordWrap: "break-word",
            },
          }}
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <MuiTableBody>
            {applicantsList.values ? (
              applicantsList.values.map((row, i) => (
                <MuiTableRow
                  key={row._links.self.href}
                  sx={{
                    color: !row.isActive && Theme.palette.grey[300],
                  }}
                >
                  <MuiTableCell align="left">
                    <MuiCheckbox
                      color="secondary"
                      size="small"
                      checked={isSelected(applicantsSelected, row)}
                      disabled={
                        !row.isActive ||
                        !row._links.addApplicant ||
                        !row._links.removeSubmissionApplicant
                      }
                      onChange={(event) => handleSelectionChange(event, row)}
                    />
                  </MuiTableCell>
                  <MuiTableCell align="center" sx={getDisabledRowStyles(row)}>
                    {row.isActive ? (
                      <Link
                        onClick={() =>
                          handleDialogOpenHandler(
                            row.applicantName,
                            row._links.self.href.split("?")[0].split("/").pop()
                          )
                        }
                      >
                        <MuiSearchIcon
                          sx={{
                            backgroundColor: Theme.palette.primary.main,
                            color: Theme.palette.common.white,
                            fill: Theme.palette.common.white,
                          }}
                          fontSize="small"
                        />
                      </Link>
                    ) : null}
                  </MuiTableCell>
                  <MuiTableCell align="left" sx={getDisabledRowStyles(row)}>
                    {row.isActive ? (
                      <>
                        <Link
                          onClick={() =>
                            handleDialogOpenHandler(
                              row.applicantName,
                              row._links.self.href
                                .split("?")[0]
                                .split("/")
                                .pop()
                            )
                          }
                          style={{ textDecoration: "none" }}
                        >
                          {row.applicantName}{" "}
                        </Link>
                        <AuditInfo
                          isEdited={row.applicantNameMod}
                          isActive={row.isActive}
                        />
                      </>
                    ) : (
                      <div align="left">
                        {row.applicantName}{" "}
                        <AuditInfo
                          isEdited={row.applicantNameMod}
                          isActive={row.isActive}
                        />
                      </div>
                    )}
                  </MuiTableCell>
                  <MuiTableCell align="left" sx={getDisabledRowStyles(row)}>
                    <>
                      {row.departments &&
                        row.departments
                          .map((department) => department["name"])
                          .join(", ")}{" "}
                      <AuditInfo
                        isEdited={row.submissionApplicantDepartmentsMod}
                        isActive={row.isActive}
                      />
                    </>
                  </MuiTableCell>
                  <MuiTableCell align="left" sx={getDisabledRowStyles(row)}>
                    <>
                      {t(traineeTypeDropDown[row.applicantType])}{" "}
                      <AuditInfo
                        isEdited={row.applicantTypeMod}
                        isActive={row.isActive}
                      />
                    </>
                  </MuiTableCell>
                  <MuiTableCell align="left" sx={getDisabledRowStyles(row)}>
                    <>
                      {row.applicationYear
                        ? row.applicationYear
                        : t(
                            "trainee.mainView.list.tableColumns.notAvailable"
                          )}{" "}
                      <AuditInfo
                        isEdited={row.applicationYearMod}
                        isActive={row.isActive}
                      />
                    </>
                  </MuiTableCell>
                  <MuiTableCell align="left" sx={getDisabledRowStyles(row)}>
                    <>
                      {row.acceptedYear
                        ? row.acceptedYear
                        : t(
                            "trainee.mainView.list.tableColumns.notAvailable"
                          )}{" "}
                      <AuditInfo
                        isEdited={row.acceptedYearMod}
                        isActive={row.isActive}
                      />{" "}
                    </>
                  </MuiTableCell>
                  <MuiTableCell align="left" sx={getDisabledRowStyles(row)}>
                    <>
                      {row.isNewEntrant
                        ? t("trainee.mainView.list.tableColumns.yes")
                        : t("trainee.mainView.list.tableColumns.no")}
                      <AuditInfo
                        isEdited={row.newEntrantMod}
                        isActive={row.isActive}
                      />
                    </>
                  </MuiTableCell>
                  <MuiTableCell align="left" sx={getDisabledRowStyles(row)}>
                    <>
                      {row.isGrantEligible
                        ? t("trainee.mainView.list.tableColumns.yes")
                        : t("trainee.mainView.list.tableColumns.no")}
                      <AuditInfo
                        isEdited={row.grantEligibleMod}
                        isActive={row.isActive}
                      />
                    </>
                  </MuiTableCell>
                  <MuiTableCell align="left" sx={{ alignContent: "center" }}>
                    {row._links.editSubmissionApplicantDetails ? (
                      <AddRemoveActionIcons
                        row={row}
                        isRowActive={row.isActive}
                        rowId={row._links.self.href.split("/").pop()}
                        editPermission={
                          row._links.editSubmissionApplicantDetails
                        }
                        removePermission={row._links.removeSubmissionApplicant}
                        addPermission={row._links.addApplicant}
                        toggleActive={toggleApplicant}
                        handleConfirmation={handleConfirmation}
                        editPageUrl={`/applicants/${
                          row._links.editSubmissionApplicantDetails.href.split(
                            "/"
                          )[6]
                        }/edit/${row._links.editSubmissionApplicantDetails.href
                          .split("?")[0]
                          .split("/")
                          .pop()}`}
                      />
                    ) : (
                      ""
                    )}
                  </MuiTableCell>
                </MuiTableRow>
              ))
            ) : (
              <MuiTableRow>
                <MuiTableCell colSpan="10">
                  <MuiTypography variant="body2" color="inherit" align="center">
                    {t("applicants.mainView.list.noData")}
                  </MuiTypography>
                </MuiTableCell>
              </MuiTableRow>
            )}
          </MuiTableBody>
        </MuiTable>
        <MuiTablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={applicantsList.totalCount || 0}
          rowsPerPage={pageSize}
          page={+page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </MuiTableContainer>
      {/* Dialog Box Output */}
      {dialogBox}
    </>
  );
};
